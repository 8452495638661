//----------------------------------------------
// Imports
//----------------------------------------------
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints';
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_animation";
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_colors";

.recentSearchListItem {
  display: inline-flex;
}

.recentSearch {
  background-color: $gi-white;
  border: 1px solid $primary-color-highlight;
  border-radius: .3125em;
  color: $primary-color-highlight;
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  align-items: center;
  padding: 15px .75em;
  margin-right: 10px;
  margin-bottom: 8px;
  transition: background-color $complex-duration ease;


  .holder {
    display: inline-flex;
  }

  .clearIcon {
    all: unset;
    cursor: pointer;
    flex-shrink: 0;
    margin-left: .75em;

    svg {
      height: 10px;
      width: 10px;
    }
  }

  .searchIcon {
    cursor: pointer;
    display: block;
    flex-shrink: 0;
    height: 16px;
    width: 16px;
    margin-right: 0.75em;
  }

  .link {
    cursor: pointer;
    text-decoration: none;

    &:focus, &:hover, &:active {
      text-decoration: none;
    }

    color: $primary-color-highlight;
  }

  &:hover, &.selected {
    background-color: $primary-color-highlight;
    color: $gi-white;

    & a {
      color: $gi-white;
    }

    .link {
      color: $gi-white;
    }
  }
}
