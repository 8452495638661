//----------------------------------------------
// Imports
//----------------------------------------------
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_media_breakpoints.scss";
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_config";

.container {
  @media #{$istock-medium-up} {
    flex-basis: 30%;
    margin-right: 2em;
  }

  .header {
    display: flex;
    align-items: center;
    padding: 8px 0 1.5em 0;

    .recentSearchesTitle {
      color: $grey;
      display: block;
      font-size: 0.75rem;
      font-weight: bold;
    }

    .textDivider {
      border-left: 1px solid $grey;
      height: .75em;
      margin: 0 10px;
    }

    .clearAll {
      all: unset;
      display: block;
      color: $green;
      cursor: pointer;
      font-size: 0.75rem;
    }
  }
}
