//----------------------------------------------
// Imports
//----------------------------------------------
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints';
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_animation";
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_colors";

.container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  background-color: white;
  border: 1px black;
}

button.button {
  &Search, &Clear {
    cursor: pointer;
    background-size: contain;
    background-color: white;
    border: 0;
    height: 100%;

    &:focus {
      outline: none;
    }

    &:hover {
      path {
        fill: $primary-color;
      }
    }
  }

  &Search {

    svg {
      width: 20px;
      height: 20px;
      margin-top: 9px;
      margin-right: 15px;

      path {
        transform: scale(2.4);
      }
    }
    margin: {
      left: 2em;
    }
    @media #{$medium-up} {
      margin-left: 2em;
    }
  }
}

.icon {
  &Search, &Clear {
    display: flex;
    width: 14px;
    margin-right: 18px;
    path {
      fill: black;
    }
  }
}

.form {
  width: 100%;
}

.input {
  padding: 7px;
  height: 100%;
  width: 100%;

  background: none;
  border: none;
  box-shadow: none;
  display: inline;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  min-width: 60px;
  text-overflow: ellipsis;
  &:focus {
    outline: none;
  }
}

.autosuggest {
  left: -1px !important;
  width: 100% !important;
  top: 54px !important;
  @media #{$medium-up} {
    top: 69px !important;
  }

  border-color: $med-grey;
  border-right-color: white;
  @media #{$medium-up} {
    border-right-color: $med-grey;
  }
}
