//----------------------------------------------
// Imports
//----------------------------------------------
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_config";
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_media_breakpoints.scss";

@mixin baseSearchIcon {
  cursor: pointer;
  display: block;
  flex-shrink: 0;
  height: 16px;
  width: 16px;
  margin-right: 0.75em;
  padding-top: 4px;
}

@mixin baseLink {
  color: $dark-grey;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;


  span {
    font-weight: normal;
  }

  &:hover {
    color: $green;
  }


  &:focus, &:hover, &:active {
    text-decoration: none;
  }
}

@mixin baseClearIcon {
  color: inherit;
  flex-shrink: 0;
  margin-left: 0.75em;
  cursor: pointer;

  svg {
    height: 10px;
    width: 10px;
  }
}

@mixin baseItem {
  display: flex;
  align-items: flex-start;
  color: inherit;
  margin: 10px 0;

  @media #{$istock-medium-up} {
    margin: 15px 0;
  }

  &:hover, &.is-hovered, &:hover a, &.is-hovered a {
    color: $green;
  }
}

.item {
  @include baseItem;

  .searchIcon {
    @include baseSearchIcon;
  }

  .link {
    all: unset;
    @include baseLink;
  }

  .clearIcon {
    all: unset;
    @include baseClearIcon;
  }
}

.selectedItem {
  @include baseItem;
  color: $green;

  .searchIcon {
    @include baseSearchIcon;
    color: $green;
  }

  .link {
    all: unset;
    @include baseLink;
    color: $green;
  }

  .clearIcon {
    all: unset;
    @include baseClearIcon;
    path {
      fill: $green;
    }
  }
}
