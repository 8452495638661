.container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.list {
  overflow: hidden;
  min-width: 200px;
}

@media screen and (max-width: 853px) {
  .container {
    flex-direction: column-reverse;
  }
}