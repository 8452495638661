//----------------------------------------------
// Imports
//----------------------------------------------
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_animation';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_colors';
@import 'unisporkal-styles/stylesheets/unisporkal/shared/config/_functions';

@mixin baseSearchIcon {
  cursor: pointer;
  display: block;
  flex-shrink: 0;
  height: 16px;
  width: 16px;
  margin-right: 0.75em;
  margin-left: 3px;
}

@mixin baseLink {
  color: $gi-black;
  cursor: pointer;
  text-decoration: none;
  color: $gi-black;
  font-weight: bold;
  margin-left: 10px;
  font-size: rem(16);

  span {
    font-weight: normal;
  }

  &:hover {
    color: $confirmed-color;
  }

  &:focus, &:hover, &:active {
    text-decoration: none;
  }
}

@mixin baseClearIcon {
  color: inherit;
  flex-shrink: 0;
  margin-left: 0.75em;
  cursor: pointer;

  svg {
    height: 10px;
    width: 10px;
  }
}

@mixin baseItem {
  display: flex;
  align-items: center;
  color: inherit;
  margin: 15px 0;

  @media #{$medium-up} {
    margin: 20px 0;
  }

  &:hover, &.is-hovered, &:hover a, &.is-hovered a {
    color: $confirmed-color;
  }
}

.item {
  @include baseItem;

  .searchIcon {
    @include baseSearchIcon;
  }

  .link {
    all: unset;
    @include baseLink;
  }

  .clearIcon {
    all: unset;
    @include baseClearIcon;
  }
}

.selectedItem {
  @include baseItem;
  color: $confirmed-color;

  .searchIcon {
    @include baseSearchIcon;
    color: $confirmed-color;
  }

  .link {
    all: unset;
    @include baseLink;
    color: $confirmed-color;
  }

  .clearIcon {
    all: unset;
    @include baseClearIcon;
    path {
      fill: $confirmed-color;
    }
  }
}
