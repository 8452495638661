//----------------------------------------------
// Imports
//----------------------------------------------
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_animation";
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_colors";

.wrapper {
  --ratio: calc(var(--width) / var(--height));
  --row-height: 8em;
  flex-basis: calc(var(--ratio) * var(--row-height));
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin: 3px;

  .image {
    width: 100%;
    height: 100%;
  }

  .clearIcon {
    color: $gi-black;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 5px;
    right: 5px;
    visibility: hidden;
    cursor: pointer;
    background-repeat: no-repeat;
    background-color: $gi-white;

    svg {
      height: 10px;
      width: 10px;
      position: absolute;
      top: 5px;
      right: 5px;
    }
    &:hover {
      background-color: $gi-black;
      color: $gi-white;
    }
  }
}

.wrapper:hover {
  .imageContainer:after {
    background-color: rgba($gi-black, 0.3);
  }
  .clearIcon {
    visibility: visible;
  }
}
