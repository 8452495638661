//----------------------------------------------
// Imports
//----------------------------------------------
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_media_breakpoints.scss";
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_config";
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_icons";


.container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: white;
  height: 54px;
  border-width: 0px;

  @media #{$istock-medium-up} {
    height: 59px;
  }

  &SearchbarRefresh {
    height: 100%;

    @media #{$istock-medium-up} {
      height: 100%;
    }

    .IconSearch {
      width: 38px;
    }
  }

}

button.button {
  &Search, &Clear {
    background-color: transparent;
    background-size: contain;
    border: none;
    box-shadow: none;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 48px;

    &:focus {
      outline: none;
      background-color: transparent;
    }

    &:hover {
      background-color: transparent;
      path {
        fill: #000;
      }
    }
  }

  &Search {
    margin: {
      left: 10px;
      right: 0px;
    }
    svg {
      width: 48px;
    }

    path {
      fill: #6c6c6c
    }

    @media #{$istock-medium-up} {
      margin: {
        left: 15px;
        right: 5px;
      }
    }
  }
}

.divider {
  border-left: 1px solid $some-grey-border;
}

.icon {
  &Search {
    width: 36px;
    @media #{$istock-medium-up} {
      width: 48px;
    }
    path {
      fill: #6c6c6c;
    }
  }

  &Clear {
    width: 15px;
    height: 15px;
    path {
      fill: #6c6c6c;
    }
  }
}

.form {
  width: 100%;
  margin-left: 7px;
  position: relative;
  @media #{$istock-medium-up} {
    margin-left: 2px;
  }
}

.input {
  padding: 7px;
  height: 100%;
  width: 100%;

  background: none;
  border: none;
  box-shadow: none;
  display: inline;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  min-width: 60px;
  text-overflow: ellipsis;

  &:focus {
    border-width: 0px;
    outline: none;
  }
}

.autosuggest {
  left: -1px !important;
  width: 100% !important;
  top: 54px !important;
  @media #{$istock-medium-up} {
    top: 69px !important;
  }

  border-color: $some-grey-border;
  border-right-color: white;
  @media #{$istock-medium-up} {
    border-right-color: $some-grey-border;
  }
}


// TOOLTIP

.dismissibleTooltip {
  position: absolute;
  left: 18.5em;
  padding: 12px 16px;
  right: auto;
  z-index: 10;
}

.newIcon {
  @extend .badge-new-text;
  margin-right: 5px;
  top: -1px;
  position: relative;
}

.tooltipContentWrapper {
  line-height: 16px;
}

.tooltipContent {
  color: #0c0d0d;
  font-size: 14px;

  .bold {
    font-weight: 600;
  }
}
