.container {
  display: inline-block;
  flex: 1.5;
  margin-left: 2em;
  overflow: hidden;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.header {
  margin: 0 0 0.5rem 0;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  &::after {
    --width: 2;
    --height: 1;
    --ratio: calc(var(--width) / var(--height));
    --row-height: 11em;
    flex-basis: calc(var(--ratio) * var(--row-height));
    content: '';
    flex-grow: 1000000;
  }
}

.srpLink {
  all: unset;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
}
