//----------------------------------------------
// Imports
//----------------------------------------------
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_media_breakpoints.scss";
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_config";

.container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.list {
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}
