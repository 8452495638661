//----------------------------------------------
// Imports
//----------------------------------------------
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints';
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_animation";
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_colors";
@import "unisporkal-styles/stylesheets/unisporkal/getty/base/_icons";

.container {
  height: 45px;
  border: 0;
  border-left: 1px solid #A6A6A6;
  background-color: $gi-white;
  cursor: pointer;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-right: 1.5em;
  padding-left: 1.5em;

  // @media #{$medium-up} {
  //   padding-right: 1.5em;
  //   padding-left: 1.5em;
  // }

  position: relative;

  .icon {
    border: 0 transparent;
    vertical-align: middle;
    g {
      fill: $gi-black;
    }
  }

  .label {
    font-size: 10px;
    color: $gi-black;
    line-height: 1;
    display: none;
    margin-top: 2px;
    min-width: 74px;

    @media #{$large-up} {
      display: flex;
    }
  }
}

.container:hover {
  .icon g {
    fill: $primary-color;
  }

  .label {
    color: $primary-color;
  }
}

.container:focus {
  outline: 0;
}

.holder {
  position: relative;
  background-color: $gi-white;
  padding-top: 6px;
  z-index: 1;
}
