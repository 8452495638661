//----------------------------------------------
// Imports
//----------------------------------------------
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_config";


.container {
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  
  margin-right: .7rem;
}

.button {
  position: relative;
  flex: 0 0 auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 36px;
  max-width: 243px;
  height: 36px;
  padding-right: .5rem;

  margin: {
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }

  border: 1px solid $some-grey-border;
  border-radius: 5px;
  background-color: $some-grey;
  color: $alpha_gray;
  font-size: .875rem;
  cursor: pointer;

  &:hover {
    border: 1px solid black;

    .closeIcon {
      fill: black;
    }
  }

  &:last-child {
    flex: 0 0 auto;
  }
}

.text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: .5rem;
  color: black;
}

.closeIcon {
  margin-left: 16px;
  opacity: 1;
  pointer-events: none;
  width: 8px;

  fill: $light-grey;
}

.thumbIcon {
  fill: $shale-grey;
  margin-left: .5rem;
}

.poortfolioIcon {
  height: 34px;
  width: 34px;
  padding: 5px;
  fill: $shale-grey;
}

.contextImage {
  height: 34px;
  max-width: 61px;

  border: {
    top-left-radius: 4px;
    bottom-left-radius: 4px;
  }
}

.contextColors {
  display: flex;
  flex-direction: row;
  height: 34px;
  width: 36px;

  border: {
    top-left-radius: 4px;
    bottom-left-radius: 4px;
    right: 1px solid $light-grey;
  }
}

.contextColor {
  height: 34px;
  width: 12px;

  &:first-child {
    border: {
      top-left-radius: 4px;
      bottom-left-radius: 4px;
    }
  }
}
