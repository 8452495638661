//----------------------------------------------
// Imports
//----------------------------------------------
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints';
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_animation";
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_colors";

.container {
  @media #{$medium-up} {
    flex-basis: 30%;
    margin-right: 2em;
  }

  .header {
    display: flex;
    align-items: center;
    padding: 8px 0 1.5em 0;

    .recentSearchesTitle {
      color: $grey-70;
      display: block;
      font-size: 0.75rem;
      font-weight: bold;
    }

    .textDivider {
      border-left: 1px solid $grey-70;
      height: .75em;
      margin: 0 10px;
    }

    .clearAll {
      all: unset;
      display: block;
      color: $confirmed-color;
      cursor: pointer;
      font-size: 0.75rem;
    }
  }
}
