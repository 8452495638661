//----------------------------------------------
// Imports
//----------------------------------------------
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_media_breakpoints.scss";
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_config";

.container {
  flex: 1.5;
  display: none;
  @media #{$istock-large-up} {
    display: inline-block;
  }

  .header {
    display: flex;
    align-items: flex-start;
    position: relative;
    padding-top: 8px;

    .title {
      color: $grey;
      font-weight: bold;
      font-size: 0.75rem;
    }

    .textDivider {
      border-left: 1px solid $grey;
      height: .75em;
      margin: 0 10px;
      margin-top: 7px;
    }

    .clearAll {
      all: unset;
      display: block;
      color: $green;
      cursor: pointer;
      font-size: 0.75rem;
    }

    .viewAll {
      display: block;
      color: $green;
      cursor: pointer;
      font-size: 0.75rem;
      margin-left: auto;
    }
  }

  .assetContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 14px auto 8px auto;
    padding-left: 14px;

    @media #{$istock-large-up} {
      padding-bottom: 0px;
    }
  }
}
