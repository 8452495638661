//----------------------------------------------
// Imports
//----------------------------------------------
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_config";

.recentSearchListItem {
  display: inline-flex;
}

.recentSearch {
  background-color: white;
  border: 1px solid $some-grey-border;
  border-radius: .3125em;
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  align-items: center;
  padding: 10px .75em;
  margin-right: 10px;
  margin-bottom: 8px;
  transition: background-color 0.300s ease;


  .holder {
    display: inline-flex;
  }

  .clearIcon {
    all: unset;
    cursor: pointer;
    flex-shrink: 0;
    margin-left: .75em;

    svg {
      height: 10px;
      width: 10px;
      fill: black;
    }
  }

  .searchIcon {
    cursor: pointer;
    display: block;
    flex-shrink: 0;
    height: 16px;
    width: 16px;
    margin-right: 0.75em;

    svg {
      fill: $shale-grey
    }
  }

  .link {
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;

    &:focus, &:hover, &:active {
      text-decoration: none;
    }

    color: black;
  }

  &:hover, &.selected {
    background-color: $green;
    color: white;

    & a {
      color: white;
    }

    .link {
      color: white;
    }

    .clearIcon {
      svg {
        fill: white;
      }
    }

    .searchIcon {
      svg {
        fill: white;
      }
    }
  }
}
