@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_config";
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_media_breakpoints.scss";

.asset {
  cursor: pointer;
  margin: 3px;
  position: relative;

  flex-grow: calc(var(--ratio) * 100);
  --ratio: calc(var(--width) / var(--height));
  --row-height: 5em;
  flex-basis: calc(var(--ratio) * var(--row-height));

  &.is-single-row {
    flex: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media #{$istock-large-up} {
    --row-height: 6.5em;
  }

  @media #{$istock-xlarge-up} {
    --row-height: 8em;
  }
}