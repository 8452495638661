//----------------------------------------------
// Imports
//----------------------------------------------
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_media_breakpoints.scss";
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_config";

.container {
  background-color: $some-grey;
  display: flex;
  padding: 1em 2em;
  position: relative;

  box-shadow: 0px 11px 10px -9px grey;
  border-top: 1px solid $some-grey-border;
  border-bottom: 1px solid $some-grey-border;

  &SearchbarRefresh {
    background-color: white;
}
  
  @media #{$istock-medium-up} {
    border: {
      right: 1px solid $some-grey-border;
      left: 1px solid $some-grey-border;
    }
    max-height: 80vh;
  }

  &:empty {
    display: none;
  }
}


