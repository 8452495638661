//----------------------------------------------
// Imports
//----------------------------------------------
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints';
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_animation";
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_colors";

.container {
  flex: 1.5;
  display: none;
  @media #{$medium-up} {
    display: inline-block;
  }

  .header {
    display: flex;
    align-items: flex-start;
    position: relative;
    padding-top: 8px;

    .title {
      color: $grey-70;
      font-weight: bold;
      font-size: 0.75rem;
    }

    .textDivider {
      border-left: 1px solid $grey-70;
      height: .75em;
      margin: 0 10px;
    }

    .clearAll {
      all: unset;
      display: block;
      color: $confirmed-color;
      cursor: pointer;
      font-size: 0.75rem;
    }

    .viewAll {
      background: none;
      border-radius: 0.3125em;
      cursor: pointer;
      outline: none;
      text-align: center;
      text-decoration: none;
      transition: all 0.3s ease;

      background-color: #FFFFFF;
      border: 1px solid #080808;
      color: #080808;

      padding: 0.4rem 1.2rem;
      margin-left: auto;
      margin-top: -10px;
      font-size: 0.75rem;

      &:focus, &:hover {
        color: #FFFFFF;
        background-color: #080808;
        text-decoration: none;
      }
    }
  }

  .assetContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 14px auto 8px auto;
    padding-left: 14px;

    @media #{$large-up} {
      padding-bottom: 0px;
    }
  }
}
