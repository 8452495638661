//----------------------------------------------
// Imports
//----------------------------------------------
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints';
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_animation";
@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_colors";

.container {
  background-color: $gi-white;
  display: flex;
  padding: 1em 2em;
  width: 100%;
  border-top: 1px solid $gi-black;
  border-bottom: 1px solid $gi-black;

  @media #{$medium-up} {
    border: 1px solid $gi-black;
    border-top: 0;
  }

  &:empty {
    display: none;
  }
}

.hidden {
  display: none;
}
