//----------------------------------------------
// Imports
//----------------------------------------------
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_media_breakpoints.scss";
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_config";
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_icons";

.container {
  align-items: center;
  background-color: $white;
  border: none;
  border-left: 1px solid $some-grey-border;
  border-radius: 0px;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 0 0 0 10px;
  padding: 0 1.5em;
  position: relative;

  &:hover {
    background-color: white;
  }

  @media #{$istock-large-up} {
    display: flex;
    padding-right: 1.5em;
    padding-left: 1.5em;
  }

  .icon {
    border: 0 transparent;
    vertical-align: middle;
    transform: scale(0.95);
  }

  .label {
    font-size: 10px;
    color: $shale-grey;
    line-height: 1;
    display: none;
    min-width: 80px;

    @media #{$istock-large-up} {
      display: flex;
    }
  }
}

.container:hover {
  .icon g {
    fill: $dark-grey;
  }
  .label {
    color: $dark-grey;
  }
}

.container:focus {
  outline: 0;
  background-color: transparent;
}

.holder {
  position: relative;
}

// TOOLTIP

.dismissibleTooltip {
  padding: 10px 24px 10px 12px;
  top: 50px;
  left: -60px;
  right: auto;
  z-index: 1;

  &::after {
    left: 90%;
  }

  @media #{$istock-medium-up} {
    top: 50px;
    left: -45px;

    &::after {
      left: 85%;
    }
  }

  @media #{$istock-large-up} {
    left: 6px;
    top: 63px;

    &::after {
      left: 75%;
    }
  }
}

.newIcon {
  @extend .badge-new-text;
  margin-right: 5px;
  top: -1px;
  position: relative;
}

.tooltipContentWrapper {
  line-height: 14px;
}

.tooltipContent {
  color: #0c0d0d;
  font-size: 12px;
}
